import React, { useEffect, useState } from "react"
import styles from "./Login.module.scss"
import Stripe from "stripe"
import axios from "axios"
import { API_ROUTE, toasted } from "../../utils"
import toast from "react-hot-toast"
import Logo from "../Logo/Logo"
import { UserInterface } from "../../App"
import connectWithStripe from "./stripe.png"

interface LoginInterface {
    entryType: "login" | "register"
    setEntryType: React.Dispatch<React.SetStateAction<"login" | "register">>
    setUser: React.Dispatch<React.SetStateAction<UserInterface>>
}

const Login: React.FC<LoginInterface> = ({ entryType, setEntryType, setUser }) => {
    const [loading, setLoading] = useState(false)
    const [buttonMessage, setButtonMessage] = useState("Continue")
    const [formPage, setFormPage] = useState(0)

    async function submit() {
        if (loading) return
        const email = (document.querySelector("input[name=email]") as HTMLInputElement).value
        const password = (document.querySelector("input[name=password]") as HTMLInputElement).value

        async function register({
            apiKey,
            name,
            email,
            password,
        }: {
            apiKey: string
            name: string
            email: string
            password: string
        }) {
            try {
                const stripe = new Stripe(apiKey)
                const me = await stripe.accounts.retrieve()

                setLoading(true)
                const response = await axios.post(API_ROUTE + "/register", {
                    apiKey,
                    name,
                    email,
                    password,
                    store: me.settings?.dashboard.display_name,
                    location: me.settings?.dashboard.timezone,
                })

                setButtonMessage("Hi There! We're getting your store ready...")
                setUser(response.data)
            } catch (e) {
                setLoading(false)
                const error = (e as any)?.response?.data?.message || (e as any).message
                toast.error(error, toasted())
            }
        }

        async function login(email: string, password: string) {
            try {
                setLoading(true)
                const response = await axios.post(API_ROUTE + "/verify-user", {
                    email,
                    password,
                })

                setButtonMessage("Welcome Back! We're getting your dashboard ready...")
                setUser(response.data)
            } catch (e) {
                setLoading(false)
                const error = (e as any)?.response?.data?.message || (e as any).message
                toast.error(error, toasted())
            }
        }

        switch (entryType) {
            case "register":
                const apiKey = (document.querySelector("input[name=api]") as HTMLInputElement).value
                const name = (document.querySelector("input[name=name]") as HTMLInputElement).value

                if (!apiKey || !name || !email || !password) {
                    toast.error("Please fill out all fields", toasted("signup"))
                    return
                }

                register({ apiKey, name, email, password })
                break
            case "login":
                if (!email || !password) {
                    toast.error("Please fill out all fields", toasted("login"))
                    return
                }

                login(email, password)
                break
        }
    }

    useEffect(() => {
        function handleEnterKey(e: KeyboardEvent) {
            if (e.key === "Enter") submit()
        }

        document.addEventListener("keyup", handleEnterKey)
        return () => document.removeEventListener("keyup", handleEnterKey)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <div className="backdrop"></div>
            <div className={styles.Login}>
                <div className="flex">
                    <Logo color="black" width={80} height={"100%"} />

                    {formPage === 0 ? (
                        <h1>Welcome {entryType === "register" ? "to UpStore!" : "Back!"}</h1>
                    ) : (
                        <h1>
                            Integrate UpStore with{" "}
                            <a href="https://stripe.com" target="_blank" rel="noreferrer">
                                Stripe
                            </a>
                        </h1>
                    )}
                </div>

                <div style={{ display: formPage === 0 ? "block" : "none" }}>
                    <p>
                        {entryType === "register"
                            ? "UpStore your own E-Commerce shop and start selling instantly."
                            : "Login to your UpStore account to manage your store and view your sales."}
                    </p>
                    <div className={styles.filter}>
                        <div
                            style={{
                                background: entryType === "register" ? "white" : undefined,
                            }}
                            onClick={() => setEntryType("register")}>
                            Register
                        </div>
                        <div
                            style={{ background: entryType === "login" ? "white" : undefined }}
                            onClick={() => setEntryType("login")}>
                            Login
                        </div>
                    </div>

                    <label>{entryType === "register" ? "Basic" : "Login"} Details</label>

                    {entryType === "register" && (
                        <div className="flex">
                            <i className="material-symbols-rounded">account_box</i>
                            <input type="text" name="name" placeholder="Full Name" />
                        </div>
                    )}

                    <div className="flex">
                        <i className="material-symbols-rounded">alternate_email</i>
                        <input type="email" name="email" placeholder="email@address.com" />
                    </div>
                    <div className="flex">
                        <i className="material-symbols-rounded">password</i>
                        <input type="password" name="password" placeholder="Password" />
                    </div>
                </div>

                <div style={{ display: formPage === 1 ? "block" : "none" }}>
                    {entryType === "register" && (
                        <>
                            <div className={styles.message}>
                                <div onClick={() => setFormPage(0)} className={styles.back}>
                                    <i className="material-symbols-rounded">chevron_left</i>
                                    Back
                                </div>
                                <div className="flex">
                                    <i
                                        style={{ color: "var(--theme)" }}
                                        className="material-symbols-rounded">
                                        info
                                    </i>

                                    <p>
                                        Stripe is a transaction platform that handles business tax,
                                        makes payments secure, fast, and easy. This will be how
                                        you'll receive your payments.
                                    </p>
                                </div>
                            </div>

                            <br />

                            <center hidden>
                                <a href="//stripe.com" target="_blank" rel="noreferrer">
                                    <img
                                        src={connectWithStripe}
                                        width={"auto"}
                                        height={50}
                                        alt="stripe"
                                    />
                                </a>
                                <br /> <br />
                                <a href="./">Skip this step and link later</a>
                                <br /> <br />
                            </center>
                            <p hidden>
                                We'll need your{" "}
                                <a
                                    href="https://dashboard.stripe.com/apikeys"
                                    target="_blank"
                                    rel="noreferrer">
                                    Stripe API secret key
                                </a>{" "}
                                so we can process payments and business tax on your behalf. This
                                will also be how you'll receive your payments.
                            </p>
                            <label htmlFor="api">Link your Stripe Live API Key</label>
                            <div className="flex">
                                <i className="material-symbols-rounded">vpn_key</i>
                                <input type="text" name="api" placeholder="sk_live_xxxxxxx" />
                            </div>
                        </>
                    )}
                </div>

                <button
                    disabled={loading}
                    onClick={() => {
                        if (formPage === 0 && entryType === "register") {
                            setFormPage(1)
                            return
                        }
                        if (!loading) submit()
                    }}>
                    {loading ? (
                        <div className="loader"></div>
                    ) : (
                        <i className="material-symbols-rounded">login</i>
                    )}
                    {buttonMessage}
                </button>
            </div>
        </>
    )
}

export default Login
