import React, { useEffect, useState } from "react"
import Dashboard from "./components/Dashboard/Dashboard"
import StoreManagement from "./components/StoreManagement/StoreManagement"
import Stripe from "stripe"
import Login from "./components/Login/Login"
import Store from "./components/Store/Store"
import { Routes, Route } from "react-router-dom"
import Home from "./components/Home/Home"
import axios from "axios"
import { API_ROUTE, paginate, toasted } from "./utils"
import toast from "react-hot-toast"

export interface UserInterface {
    name: string
    email: string
    apiKey: string
    managing: string[]
    location: string
}

export interface StoreDetailsInterface {
    name: string
    created: number
    apiKey: string
    owners: string[]
    contact: string
    description: string
    banner: string
    icon: string
    location: string
}

const App: React.FC = () => {
    const [user, setUser] = useState<UserInterface>({} as UserInterface)
    const [entryType, setEntryType] = useState<"login" | "register">("login")
    const [products, setProducts] = useState<Stripe.Product[]>([])
    const [prices, setPrices] = useState<Stripe.Price[]>([])
    const [transactions, setTransactions] = useState<Stripe.BalanceTransaction[]>([])
    const [charges, setCharges] = useState<Stripe.Charge[]>([])
    const [storeDetails, setStoreDetails] = useState<StoreDetailsInterface>(
        {} as StoreDetailsInterface
    )
    const [balance, setBalance] = useState<{ available: number; pending: number }>({
        available: 0,
        pending: 0,
    })

    const store = new URLSearchParams(window.location.search).get("s")

    const refresh = async (critical = false) => {
        if (!window.location.hash || window.location.search) return
        if (!store && !user.apiKey) {
            window.location.href = "#/login"
            return
        }
        try {
            const stripe = new Stripe(user.apiKey)
            if (critical) {
                setProducts(await paginate(stripe, "products"))
                setPrices(await paginate(stripe, "prices"))
                return
            }

            const balance = await stripe.balance.retrieve()
            setBalance({
                available: balance.available[0].amount / 100,
                pending: balance.pending[0].amount / 100,
            })

            setProducts(await paginate(stripe, "products"))
            setTransactions(await paginate(stripe, "balanceTransactions"))
            setStoreDetails((await axios.get(`${API_ROUTE}/get-store/${user.managing[0]}`)).data)

            // Deferred for later
            setPrices(await paginate(stripe, "prices"))
            setCharges(await paginate(stripe, "charges"))
        } catch (e) {
            toast.error(
                "Sorry, we're having trouble connecting to Stripe.",
                toasted("stripe-error")
            )
        }
    }

    useEffect(() => {
        const credentials = localStorage.getItem("credentials")
        if (credentials && window.location.hash === "#/login") {
            toast.loading("Automatically logging you in...", toasted("auto-login"))
            setUser(JSON.parse(credentials))
        }
    }, [])

    useEffect(() => {
        if (storeDetails.name && window.location.hash === "#/login") {
            toast.dismiss()
            window.location.href = "#/dashboard"
            return
        }
        refresh()
        if (user.name && user.email && user.apiKey) {
            localStorage.setItem("credentials", JSON.stringify(user))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, store, storeDetails])

    if (store) return <Store />

    return (
        <Routes>
            <Route path="/" element={<Home setEntryType={setEntryType} />} />
            <Route
                path="/login"
                element={
                    <Login setUser={setUser} entryType={entryType} setEntryType={setEntryType} />
                }
            />
            <Route
                path="/mystore"
                element={
                    <StoreManagement
                        storeDetails={storeDetails}
                        setStoreDetails={setStoreDetails}
                        user={user}
                        refresh={refresh}
                        products={products}
                        prices={prices}
                    />
                }
            />
            <Route
                path="/dashboard"
                element={
                    <Dashboard
                        storeDetails={storeDetails}
                        user={user}
                        refresh={refresh}
                        products={products}
                        transactions={transactions}
                        charges={charges}
                        balance={balance}
                    />
                }
            />
        </Routes>
    )
}

export default App
