import React from "react"
import styles from "./Home.module.scss"
import { Link } from "react-router-dom"
import Logo from "../Logo/Logo"
import Demo from "../demo/Demo"
import storeImage from "./products.png"
import managementImage from "./management.png"
import dashboardImage from "./dashboard.png"

/**
 * Possible headlines:
 * - UpStore your Store.
 * - UpStore your Business.
 *
 * - Retail your world with UpStore.
 *
 * - UpStore \n where online retail becomes accessible.
 * - UpStore \n where e-commerce becomes accessible.
 *
 * DEMO:
 * - create a product for mens shirt small size, give it a reasonable price and make a good description that sells well.
 * - im trying to sell my airpods pro 2nd gen, can you help find images and write up something than can sell?
 */

const Home: React.FC<{
    setEntryType: React.Dispatch<React.SetStateAction<"login" | "register">>
}> = ({ setEntryType }) => {
    /* eslint-disable */
    // return <Demo />

    return (
        <div className={styles.Home}>
            <div className="backdrop"></div>
            <nav>
                <Logo width={75} height={"100%"} color="black" />
                <div className={styles.separator}></div>
                <div className="flex">
                    <Link
                        onClick={() => setEntryType("login")}
                        className={styles.login}
                        to={"/login"}>
                        <i className="material-symbols-rounded">account_circle</i> Login
                    </Link>
                    <Link
                        onClick={() => setEntryType("register")}
                        className={styles.register}
                        to={"/login"}>
                        <i className="material-symbols-rounded">create_new_folder</i>
                        UpStore your Business
                    </Link>
                </div>
            </nav>

            <div className={styles.container}>
                <div className={styles.left}>
                    <div className={styles.notice}>
                        FREE for the first 100 signups –– UpStore your business for free, now, not
                        later.
                    </div>

                    <h1>
                        <span className={styles.brand}>UpStore</span> <br />
                        Where e-commerce becomes accessible.
                    </h1>

                    <div className={styles.showcaseGrid}>
                        <div className={styles.item}>
                            <div className={styles.title}>Sell instantly</div>
                            <img src={storeImage} alt="demo" />
                        </div>
                        <div className={styles.item}>
                            <div className={styles.title}>Inventory Management</div>
                            <img src={managementImage} alt="demo" />
                        </div>
                        <div className={styles.item}>
                            <div className={styles.title}>Orders and Payouts</div>
                            <img src={dashboardImage} alt="demo" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home
