import React, { useRef, useState } from "react"
import styles from "./Assistant.module.scss"
import Stripe from "stripe"
import { UserInterface } from "../../App"
import toast from "react-hot-toast"
import { API_ROUTE, formatStripeNumber, toastMessage, toasted } from "../../utils"
import axios from "axios"

interface AssistantInterface {
    user: UserInterface
    products: Stripe.Product[]
    refresh: (critical: boolean) => Promise<void>
}

const Assistant: React.FC<AssistantInterface> = ({ user, products, refresh }) => {
    const inputRef = useRef<HTMLInputElement>(null)
    const [input, setInput] = useState("")
    const [loading, setLoading] = useState(false)
    const [placeholder, setPlaceholder] = useState("Tell me what you want to do...")
    const demoQuery = "Create a new product that can be added to my store"

    const processLink = async (
        stripe: Stripe,
        price: number,
        currency: string,
        productId: string
    ) => {
        try {
            const processPrice = async (
                stripe: Stripe,
                price: number,
                currency: string,
                productId: string
            ) => {
                return await stripe.prices.create({
                    unit_amount: price * 100,
                    currency: currency,
                    product: productId,
                })
            }

            await processPrice(stripe, price, currency, productId)
        } catch (e) {
            toast.error((e as any).message, toasted())
        }
    }

    const createProduct = async (data: any, stripe: Stripe) => {
        setPlaceholder(`Almost there, now creating ${data.name}...`)

        const loadMessage = document.getElementById("loadingMessage")
        if (loadMessage) loadMessage.innerHTML = `Almost there, now creating ${data.name}...`

        const response = await fetch(data.image)

        const createdProduct = await stripe.products.create({
            name: data.name,
            description: data.description,
            active: true,
            images: [response.url],
        })
        if (data.price)
            await processLink(
                stripe,
                formatStripeNumber(Math.trunc(Number(data.price))),
                "aud",
                createdProduct.id
            )
    }

    const updateProduct = async (data: any, stripe: Stripe) => {
        const product = await stripe.products.retrieve(data.id)
        let price: null | number = null

        setPlaceholder(`Almost there, now updating ${product.name}...`)
        const loadMessage = document.getElementById("loadingMessage")
        if (loadMessage) loadMessage.innerHTML = `Almost there, now updating ${product.name}...`

        if (data.name) product.name = data.name
        if (data.description) product.description = data.description
        if (data.image) {
            const response = await fetch(data.image)
            product.images = [response.url, ...product.images]
        }
        if (data.price) price = formatStripeNumber(Number(data.price))

        await stripe.products.update(data.id, {
            name: product.name,
            description: product.description,
            active: data.active,
            images: product.images,
        })

        if (price !== null) await processLink(stripe, price, "aud", data.id)
    }

    const assist = async (input: string) => {
        if (loading || !input.trim()) return
        if (input.includes("prod_id")) {
            toast.error(
                "Please replace prod_id with an existing product ID.",
                toasted("missing-id")
            )
            return
        }
        const sampleProducts = products
            .filter((product: Stripe.Product) => product.active)
            .sort(() => Math.random() - Math.random())
            .slice(0, 10)
            .map((product: Stripe.Product) => product.name.slice(0, 40))
            .join(", ")

        toast(
            <div style={{ width: "100%" }} className="flex">
                <div className={styles.ToastUserProfile}>Me</div>
                {input}
            </div>,
            toastMessage(1e10)
        )

        const prompt = `
You are an AI assistant that helps users with managing their store products.
First, determine what the user wants to achieve: create or update a product. YOU MUST return a JSON object to be sent to the backend in JSON format:

{
    action: 'create' | 'update',
    id: string, // ONLY WHEN UPDATING A PRODUCT
    name: string,
    description: string, // be detailed as possible, given there is enough context
    price: number, // above 0.5 (AUSTRALIAN DOLLAR BY DEFAULT)
    image: string, // use unsplash url https://source.unsplash.com/512x512/?xxx replace xxx with one word that describes the product
    message: string, // a short assistant-like personalized message telling the user what you have created/what exactly you updated (eg. Got it! I've taken that product out of stock)
    active: boolean, // ONLY FALSE WHEN A USER ASKS TO TAKE A PRODUCT OUT OF STOCK/UNAVAILABLE/DELETED ETC
}

When updating, only include obj keys that the user has asked to update. Otherwise, do not include them.
IN CASE OF DEMO, THE USER WILL ASK '${demoQuery}'.
Now go and generate a realistically hypothetical product that can be added to their store, based on their existing products: ${sampleProducts}. 

THE USER HAS ASKED: '${input.trim().slice(0, 512)}'.
`

        setLoading(true)
        setInput("")

        setTimeout(() => {
            setPlaceholder("Fulfilling your request...")
            toast.loading(
                <div className="flex" style={{ width: "100%" }}>
                    <div
                        style={{ background: "#FFB300", fontSize: "2rem" }}
                        className={styles.ToastUserProfile}>
                        🤖
                    </div>
                    <span id="loadingMessage">Fulfilling your request...</span>
                </div>,
                toastMessage(1e10)
            )

            if (input === demoQuery) {
                setTimeout(() => {
                    const loadMessage = document.getElementById("loadingMessage")
                    if (loadMessage)
                        loadMessage.innerHTML =
                            "Suggesting a new product based on your existing products..."
                }, 1800)
            }
        }, 900)

        try {
            let response = (await axios.post(API_ROUTE + "/gpt", { query: prompt })).data
            const stripe = new Stripe(user.apiKey)

            switch (response.action) {
                case "create":
                    await createProduct(response, stripe)
                    break
                case "update":
                    await updateProduct(response, stripe)
                    break
                default:
                    setLoading(false)
                    toast.error(
                        <div className="flex">
                            <div
                                style={{ background: "#FFB300", fontSize: "2rem" }}
                                className={styles.ToastUserProfile}>
                                🤖
                            </div>
                            Sorry, I couldn't understand your request. Please try again.
                        </div>,
                        toastMessage()
                    )
                    setTimeout(() => toast.dismiss(), 6000)
                    return
            }

            refresh(true).then(() => {
                if (response.message) toast.success(response.message, toastMessage(6000))
                setLoading(false)
                window.location.href = "#/mystore"
                setTimeout(() => toast.dismiss(), 6000)
            })
        } catch (e) {
            setLoading(false)

            toast.error(
                <div className="flex">
                    <div
                        style={{ background: "#FFB300", fontSize: "2rem" }}
                        className={styles.ToastUserProfile}>
                        🤖
                    </div>
                    Sorry, there wasn't enough detail in your prompt. Please try again.
                </div>,
                toastMessage()
            )
            setTimeout(() => toast.dismiss(), 6000)
            return
        }
    }

    return (
        <div className={styles.Assistant}>
            <div className={styles.input}>
                <div className={styles.loading}>{loading && <div className="loader"></div>}</div>
                {!input && (
                    <div className={styles.demo}>
                        <span
                            onClick={() => {
                                setInput(demoQuery)
                                if (inputRef.current) inputRef.current.focus()
                            }}>
                            create product
                        </span>
                        <span
                            onClick={() => {
                                setInput("Increase the price of prod_id to $30")
                                if (inputRef.current) inputRef.current.focus()
                            }}>
                            edit price
                        </span>
                        <span
                            onClick={() => {
                                setInput("Take prod_id out of stock")
                                if (inputRef.current) inputRef.current.focus()
                            }}>
                            take product of out stock
                        </span>
                    </div>
                )}
                <input
                    ref={inputRef}
                    disabled={loading}
                    type="text"
                    placeholder={placeholder}
                    maxLength={512}
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") assist(input)
                    }}
                />
                <div onClick={() => assist(input)} className={styles.send}>
                    <i className="material-symbols-rounded">send</i>
                </div>
            </div>
        </div>
    )
}

export default Assistant
