import React from "react"
import styles from "../Store.module.scss"
import Stripe from "stripe"
import { canTruncate, noImage } from "../../../utils"

interface ProductInterface {
    prices: Stripe.Price[]
    setViewing: React.Dispatch<React.SetStateAction<Stripe.Product>>
    inline?: boolean
    product: Stripe.Product
}

const Product: React.FC<ProductInterface> = ({ prices, setViewing, inline, product }) => {
    const price =
        prices?.find((price: Stripe.Price) => price.product === product.id)?.unit_amount! / 100

    return (
        <div onClick={() => setViewing(product)} key={product.id} className={styles.product}>
            <div className={inline ? styles.item : undefined}>
                <div>
                    <img
                        src={
                            product?.images && product?.images.length > 0
                                ? product.images[0]
                                : noImage
                        }
                        alt="icon"
                    />
                </div>
                <div className={styles.info}>
                    <h3>
                        {canTruncate(product.name, inline ? 25 : 100)} – ${price || "..."}
                    </h3>
                    {!inline && product.description && (
                        <p>{canTruncate(product.description, 100)}</p>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Product
