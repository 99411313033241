import React from "react"
import { parseTC } from "../../../utils"
import Stripe from "stripe"
import styles from "../Dashboard.module.scss"

interface TransactionInterface {
    transaction: Stripe.BalanceTransaction
}

const Transaction: React.FC<TransactionInterface> = ({ transaction }) => {
    return (
        <div
            style={{
                background: transaction.status === "pending" ? "#ffb30030" : "#00c85330",
            }}
            className={styles.transaction}>
            <div className="flex">
                <i
                    style={{
                        color: transaction.status === "pending" ? "#ffb300" : "#00c853",
                    }}
                    className="material-symbols-rounded">
                    {transaction.status === "pending" ? "pending" : "check_circle"}
                </i>
                <div>
                    <h3>{transaction.description}</h3>
                    <span>{parseTC(transaction.created)}</span>
                </div>
            </div>
            <p>${transaction.net / 100}</p>
        </div>
    )
}

export default Transaction
