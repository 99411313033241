import React, { useRef, useState } from "react"
import axios from "axios"
import styles from "./Upload.module.scss"
import placeholderImage from "../Upload/attachment.png"
import toast from "react-hot-toast"
import { toasted } from "../../utils"
import imageCompression from "browser-image-compression"

interface UploadProps {
    style?: React.CSSProperties
    placeholder?: string | JSX.Element
    initialImage?: string
    skipLoad?: boolean
    onImageUploaded: (imageDataURL: string) => void
}

const Upload: React.FC<UploadProps> = ({
    style,
    placeholder,
    initialImage,
    skipLoad,
    onImageUploaded,
}) => {
    const apiKey = "0b888b5dd33670d2bf1e72e5522597c8"
    const formats = ["png", "jpg", "jpeg", "svg", "ico", "webp", "heic", "heif", "gif", "bmp"]
    const defaultText = "Upload Here"

    const [loadDelay, setLoadDelay] = useState(false)
    const [dragging, setDragging] = useState(false)
    const [text, setText] = useState(placeholder || defaultText)
    const uploaderRef = useRef<HTMLInputElement>(null)

    const handleFileChange = async (file: File) => {
        const type = file.name.match(/\.([^.]+)$/)![1].toLowerCase()

        if (formats.includes(type)) {
            const options = {
                maxSizeMB: 1,
                maxWidthOrHeight: 1920,
                useWebWorker: true,
            }
            try {
                console.log(`originalFile size ${file.size / 1024 / 1024} MB`)
                const compressedFile = await imageCompression(file, options)
                console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`)

                setLoadDelay(true)
                setText("Uploading...")

                const formData = new FormData()
                formData.append("image", compressedFile)
                formData.append("name", file.name) // original filename

                const response = await axios.post("https://api.imgbb.com/1/upload", formData, {
                    params: { key: apiKey },
                })

                if (response.data.status !== 200) throw new Error("Upload failed")
                onImageUploaded(response.data.data.url)
            } catch (error) {
                setLoadDelay(false)
                toast("Sorry, we couldn't process your image", toasted())
            } finally {
                skipLoad && setLoadDelay(false)
                setText(defaultText)
                if (uploaderRef.current) uploaderRef.current.value = ""
            }
        } else {
            setLoadDelay(false)
            setText(defaultText)
            toast("This image file couldn't be processed", toasted())
            if (uploaderRef.current) uploaderRef.current.value = ""
        }
    }

    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault()
        setDragging(true)
        setText("Drop image here")
    }

    const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault()
        setDragging(false)
        setText(defaultText)
    }

    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault()
        setDragging(false)
        setText(defaultText)

        const files = e.dataTransfer.files
        if (files.length > 0) {
            handleFileChange(files[0])
        }
    }

    const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files
        if (files && files.length > 0) {
            handleFileChange(files[0])
        }
    }

    return (
        <div className={styles.Upload}>
            <div
                style={{
                    filter: dragging ? "brightness(0.95)" : undefined,
                    ...style,
                }}
                className={styles.active}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
                onClick={() => uploaderRef.current?.click()}>
                <input
                    hidden
                    accept="image/*"
                    ref={uploaderRef}
                    type="file"
                    onChange={handleFileInput}
                />

                <img
                    style={{ display: initialImage && !loadDelay ? undefined : "none" }}
                    onLoad={() => setLoadDelay(false)}
                    alt="preview"
                    src={initialImage}
                    className={styles.preview}
                />

                {(!initialImage || loadDelay) && (
                    <div className={styles.text}>
                        {!loadDelay ? (
                            <img draggable={false} src={placeholderImage} alt="upload" />
                        ) : (
                            <i className="loader"></i>
                        )}
                        {text}
                    </div>
                )}
            </div>
        </div>
    )
}

export default Upload
