import React from "react"
import styles from "../Dashboard.module.scss"
import Stripe from "stripe"
import { countryCodes, parseTC } from "../../../utils"

const Order: React.FC<{ charge: Stripe.Charge }> = ({ charge }) => {
    const customerBillingDetails = charge.billing_details
    const customerShippingDetails = charge.shipping

    function mergeDetails(
        billing: Stripe.Charge.BillingDetails | null,
        shipping: Stripe.Charge.Shipping | null
    ) {
        const getCountry = (iso: string | null) => {
            if (!iso) return "--"
            const country = countryCodes.find((country: string[]) => country[0] === iso)
            if (!country) return "--"
            return `${country[1]} (${country[0]})`
        }

        const mergedDetails = {
            name: billing?.name || shipping?.name || "--",
            email: billing?.email || "--",
            phone: billing?.phone || shipping?.phone || "--",
            line1: billing?.address?.line1 || shipping?.address?.line1 || "--",
            line2: billing?.address?.line2 || shipping?.address?.line2 || null,
            city: billing?.address?.city || shipping?.address?.city || "--",
            state: billing?.address?.state || shipping?.address?.state || "--",
            postal: billing?.address?.postal_code || shipping?.address?.postal_code || "--",
            country: getCountry(billing?.address?.country || shipping?.address?.country || null),
        }
        return mergedDetails
    }

    const billing = mergeDetails(customerBillingDetails, customerShippingDetails) as any

    return (
        <div className={styles.charge}>
            <div className={styles.info}>
                <p
                    style={{
                        backgroundColor: charge.status === "succeeded" ? "#80ff7488" : "#ff808888",
                    }}
                    className={styles.status}>
                    {charge.status}
                </p>

                <p>{parseTC(charge.created)}</p>
                <h2 style={{ margin: "8px 0" }}>
                    <span style={{ textTransform: "uppercase" }}>{charge?.currency}</span> $
                    {charge.amount / 100}
                </h2>

                <p style={{ textTransform: "capitalize" }}>
                    {charge?.payment_method_details?.type}/
                    {charge?.payment_method_details?.card?.brand}
                </p>
                <p>Ending in {charge?.payment_method_details?.card?.last4}</p>
                <p>
                    Expiring: {charge?.payment_method_details?.card?.exp_month}/
                    {charge?.payment_method_details?.card?.exp_year}
                </p>

                <br />

                {charge?.receipt_url && (
                    <a
                        className="flex"
                        style={{ textDecoration: "none" }}
                        href={charge?.receipt_url}
                        target="_blank"
                        rel="noreferrer">
                        <i className="material-symbols-rounded">receipt</i>
                        <p>Receipt</p>
                    </a>
                )}
            </div>

            <div className={styles.detailsContainer}>
                <h2 style={{ fontWeight: "500" }}>Billing/Shipping</h2>

                <div className={styles.details}>
                    {Object.keys(billing).map((key: string, index: number) => {
                        if (!billing[key]) return null
                        return (
                            <div key={index}>
                                <p className={styles.name}>{key}</p>
                                {key === "email" ? (
                                    <a href={"mailto:" + billing[key]}>{billing[key]}</a>
                                ) : (
                                    billing[key]
                                )}
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default Order
