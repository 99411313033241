import React from "react"
import Stripe from "stripe"
import { canTruncate, noImage, parseTC } from "../../../utils"

interface ProductInterface {
    products: Stripe.Product[]
    setSelectedProduct: React.Dispatch<React.SetStateAction<Stripe.Product>>
    prices: Stripe.Price[]
    product: Stripe.Product
    setOpened: React.Dispatch<React.SetStateAction<boolean>>
}

const Product: React.FC<ProductInterface> = ({
    products,
    prices,
    setSelectedProduct,
    product,
    setOpened,
}) => {
    const price: number =
        prices?.find((price: Stripe.Price) => price.product === product.id)?.unit_amount! / 100

    return (
        <tr
            style={{
                background: !price && prices?.length > 0 ? "rgba(255, 0, 0, 0.05)" : undefined,
                color: !price && prices?.length > 0 ? "tomato" : undefined,
            }}
            onClick={() => {
                const prod = products.find((prod: Stripe.Product) => prod.id === product.id)
                if (prod) {
                    setSelectedProduct(prod)
                    setOpened(true)
                }
            }}>
            <td>
                <div className="flex">
                    <img
                        src={
                            product?.images && product?.images?.length > 0
                                ? product?.images[0]
                                : noImage
                        }
                        alt="icon"
                    />
                    <span>{(product.active ? "" : "❌ [N/S]") + " " + product.name}</span>
                </div>
            </td>
            <td>{product.id}</td>
            <td>{price ? `$${price}` : "No Price"}</td>
            <td>{canTruncate(product.description || "", 100)}</td>
            <td>{parseTC(product.created)}</td>
            <td>{parseTC(product.updated)}</td>
        </tr>
    )
}

export default Product
