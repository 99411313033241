import React, { useState } from "react"
import styles from "./Cart.module.scss"
import Stripe from "stripe"
import toast from "react-hot-toast"
import { toasted } from "../../../../utils"
import { StoreDetailsInterface } from "../../../../App"
import cn from "clsx"

interface CartInterface {
    storeDetails: StoreDetailsInterface
    cartOpen: boolean
    setCartOpen: React.Dispatch<React.SetStateAction<boolean>>
    setViewing: React.Dispatch<React.SetStateAction<Stripe.Product>>
    myCart: Stripe.Product[]
    setMyCart: React.Dispatch<React.SetStateAction<Stripe.Product[]>>
    prices: Stripe.Price[]
}

const Cart: React.FC<CartInterface> = ({
    storeDetails,
    cartOpen,
    setCartOpen,
    setViewing,
    myCart,
    setMyCart,
    prices,
}) => {
    const [loading, setLoading] = useState(false)

    const checkOut = async () => {
        const allowedCountries: any = []
        myCart
            .map((product: Stripe.Product) => product.metadata?.shipping)
            .forEach((shippingAddress: string) => {
                if (shippingAddress) {
                    shippingAddress.split(",").forEach((country: string) => {
                        if (!allowedCountries.includes(country)) {
                            allowedCountries.push(country)
                        }
                    })
                }
            })

        try {
            const stripe = new Stripe(storeDetails.apiKey)
            const session = await stripe.checkout.sessions.create({
                payment_method_types: ["card"],
                line_items: myCart.map((product: Stripe.Product) => ({
                    price: prices.find((price: Stripe.Price) => price.product === product.id)?.id,
                    quantity: 1,
                })),
                mode: "payment",
                success_url: window.location.href,
                cancel_url: window.location.href,
                shipping_address_collection: {
                    allowed_countries: allowedCountries,
                },
            })
            window.open(session.url!, "_blank")
        } catch (err) {
            toast.error("Sorry, we could not process your payment.", toasted())
        }
    }

    return (
        <div className={styles.Cart}>
            <div
                onClick={() => setCartOpen(false)}
                className={cn(styles.backdrop, cartOpen ? styles.active : undefined)}></div>
            <div className={cn(styles.panel, cartOpen ? styles.active : undefined)}>
                <div>
                    <div className={styles.header}>
                        <div className="flex">
                            <i
                                className="material-symbols-rounded"
                                onClick={() => setCartOpen(false)}>
                                close
                            </i>
                            <h2>My Cart</h2>
                        </div>
                        <p>{myCart.length} items</p>
                    </div>
                    <p>{myCart.length > 0 ? "Click to view an item" : "Your cart is empty."}</p>
                </div>

                <div className={styles.items}>
                    {myCart.map((product: Stripe.Product, index: number) => (
                        <div
                            key={index}
                            className={styles.item}
                            onClick={() => setViewing(product)}>
                            <i
                                className="material-symbols-rounded"
                                style={{ color: "tomato" }}
                                onClick={(e) => {
                                    e.stopPropagation()
                                    const cart = [...myCart]
                                    cart.splice(myCart.indexOf(product), 1)
                                    setMyCart(cart)
                                }}>
                                close
                            </i>
                            <div className={styles.info}>
                                <div>
                                    <h3>{product.name}</h3>
                                    <p>
                                        $
                                        {prices.find(
                                            (price: Stripe.Price) => price.product === product.id
                                        )?.unit_amount! / 100}
                                    </p>
                                </div>
                                <img src={product.images[0]} alt="product" />
                            </div>
                        </div>
                    ))}
                </div>

                {myCart.length > 0 && (
                    <div className={styles.cartFooter}>
                        <button
                            disabled={loading}
                            onClick={async () => {
                                setLoading(true)
                                await checkOut()
                                setLoading(false)
                            }}>
                            {loading ? (
                                <>
                                    <div className="loader"></div>
                                    Loading...
                                </>
                            ) : (
                                <>
                                    <i className="material-symbols-rounded">shopping_cart</i>
                                    Checkout
                                </>
                            )}
                        </button>
                    </div>
                )}
            </div>
        </div>
    )
}

export default Cart
