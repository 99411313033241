import React, { useState } from "react"
import styles from "./ViewProduct.module.scss"
import Stripe from "stripe"
import { Swiper, SwiperSlide } from "swiper/react"
import { Autoplay, Navigation, Scrollbar } from "swiper/modules"
import "swiper/scss"
import "swiper/scss/pagination"
import "swiper/scss/scrollbar"
import "swiper/scss/navigation"
import { copyToClipboard } from "../../../../utils"
import toast from "react-hot-toast"
import cn from "clsx"
import { StoreDetailsInterface } from "../../../../App"

interface ViewProductInterface {
    viewing: Stripe.Product
    setViewing: React.Dispatch<React.SetStateAction<Stripe.Product>>
    prices: Stripe.Price[]
    addToCart: (product: Stripe.Product, quantity: number) => void
    storeDetails: StoreDetailsInterface
}

const ViewProduct: React.FC<ViewProductInterface> = ({
    viewing,
    setViewing,
    prices,
    addToCart,
    storeDetails,
}) => {
    const [loading, setLoading] = useState(false)
    const [quantity, setQuantity] = useState(1)
    const [expanded, setExpanded] = useState(false)
    const currency = prices?.find((price: Stripe.Price) => price.product === viewing?.id)?.currency
    const billing_scheme = prices?.find((price: Stripe.Price) => price.product === viewing?.id)
        ?.billing_scheme

    const price =
        prices?.find((price: Stripe.Price) => price.product === viewing?.id)?.unit_amount! / 100

    const expandMore = (str: string, length = 100) => {
        return (
            <div onClick={() => setExpanded(!expanded)}>
                {expanded ? str : str.slice(0, length).trim()}
                {!expanded && str.length > length && (
                    <>
                        ...
                        <span className={styles.expand}>
                            <i className="material-symbols-rounded">expand_more</i>
                            More
                        </span>
                    </>
                )}
            </div>
        )
    }

    const close = () => {
        setExpanded(false)
        setViewing({} as Stripe.Product)
        setQuantity(1)
        setLoading(false)
    }

    const checkOut = async () => {
        const session = await new Stripe(storeDetails.apiKey).checkout.sessions.create({
            payment_method_types: ["card"],
            line_items: [
                {
                    price: prices.find((price: Stripe.Price) => price.product === viewing?.id)?.id,
                    quantity: quantity,
                },
            ],
            mode: "payment",
            success_url: window.location.href,
            cancel_url: window.location.href,
            shipping_address_collection: {
                allowed_countries: viewing?.metadata?.shipping?.split(",") as any,
            },
        })

        window.open(session.url!, "_blank")
    }

    return (
        <div className={styles.ViewProduct}>
            <div
                onClick={close}
                className={cn(
                    styles.backdrop,
                    viewing?.id ? styles.backdropActive : undefined
                )}></div>
            <div className={cn(styles.modal, viewing?.id ? styles.modalActive : undefined)}>
                <div className={styles.container}>
                    <div className={styles.close} onClick={close}>
                        <i className="material-symbols-rounded">close</i>
                    </div>

                    {viewing?.metadata?.category && (
                        <p className={styles.pathname}>
                            {storeDetails.name + " > "} {viewing?.metadata?.category}
                        </p>
                    )}

                    <h2>{viewing?.name}</h2>
                    <div className="flex">
                        <span style={{ color: "var(--subtitle)" }}>{storeDetails.name}</span>
                        <div
                            className={styles.share}
                            onClick={() => {
                                copyToClipboard(
                                    `${window.location.href}&prod=${viewing?.id.replace(
                                        "prod_",
                                        ""
                                    )}`
                                )
                                toast.success("Link copied to clipboard!")
                            }}>
                            <i className="material-symbols-rounded">share</i>
                        </div>
                    </div>

                    <br />

                    <p className={styles.price}>
                        {currency} ${price ? (price * quantity).toFixed(2) : "0.00" || "..."}
                        <span>
                            {billing_scheme === "per_unit" && (
                                <>{quantity === 1 ? "per unit" : "per " + quantity + " units"}</>
                            )}
                        </span>
                    </p>

                    {viewing?.metadata?.shippingTime &&
                        viewing?.metadata?.shippingTime !== "Select Shipping Time" && (
                            <p className="flex">
                                <i className="material-symbols-rounded">local_shipping</i>
                                {viewing?.metadata?.shippingTime}
                            </p>
                        )}

                    {viewing?.metadata?.shipping && (
                        <p className="flex">
                            <i className="material-symbols-rounded">public</i>
                            Ships to: {viewing?.metadata?.shipping?.replaceAll(",", ", ")}
                        </p>
                    )}

                    <hr />

                    <div className={styles.quantity}>
                        <label htmlFor="quantity">Select Quantity</label>
                        <select
                            name="quantity"
                            id="quantity"
                            value={quantity}
                            onChange={(e) => setQuantity(parseInt(e.target.value))}>
                            {Array.from(Array(50).keys()).map((num: number) => (
                                <option value={num + 1} key={num + 1}>
                                    {num + 1}
                                </option>
                            ))}
                        </select>
                    </div>

                    <p>{expandMore(viewing?.description || "")}</p>

                    <br />

                    {!price && prices?.length > 0 ? (
                        <div className={styles.notAvailable}>
                            Sorry, this product is currently not available, and is most likely a
                            temporary issue. Please contact the{" "}
                            <a href={`mailto:${storeDetails.contact}`}>store owner</a> for help.
                        </div>
                    ) : (
                        <div className={styles.buttons}>
                            <button
                                disabled={loading}
                                onClick={async () => {
                                    setLoading(true)
                                    await checkOut()
                                    setLoading(false)
                                }}>
                                {loading ? (
                                    <>
                                        <div className="loader"></div>
                                        Loading...
                                    </>
                                ) : (
                                    <>
                                        <i className="material-symbols-rounded">shopping_cart</i>
                                        Buy
                                    </>
                                )}
                            </button>

                            <button
                                onClick={() => {
                                    close()
                                    addToCart(viewing, quantity)
                                }}>
                                <i className="material-symbols-rounded">add_shopping_cart</i>
                                Add To Cart
                            </button>
                        </div>
                    )}

                    <a
                        className={styles.contact}
                        href={`mailto:${storeDetails.contact}?subject=Re: ${viewing?.name} on UpStore`}>
                        <i className="material-symbols-rounded">email</i>
                        Questions? Contact {storeDetails.name}
                    </a>
                </div>

                <div className={styles.gallery}>
                    {viewing?.images && viewing.images.length > 0 ? (
                        <Swiper
                            className={styles.Swiper}
                            modules={[Navigation, Autoplay, Scrollbar]}
                            spaceBetween={50}
                            slidesPerView={1}
                            navigation
                            scrollbar={{ draggable: true }}
                            autoplay={{ delay: 2000, disableOnInteraction: true }}>
                            {viewing?.images.map((attachment: string, index: number) => (
                                <SwiperSlide className={styles.SwiperSlide} key={index}>
                                    <img src={attachment} alt={"product"} />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    ) : (
                        <p>Oops! There's no image available for this product.</p>
                    )}
                </div>
            </div>
        </div>
    )
}

export default ViewProduct
