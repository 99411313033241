import React, { useEffect, useState } from "react"
import Stripe from "stripe"
import Upload from "../Upload/Upload"
import { canTruncate, formatStripeNumber, noImage, parseTC, toasted } from "../../utils"
import { UserInterface } from "../../App"
import styles from "./ProductManagement.module.scss"
import ShippingOptions from "./components/ShippingOptions"
import toast from "react-hot-toast"
import cn from "clsx"

interface ProductManagementInterface {
    product: Stripe.Product
    user: UserInterface
    prices: Stripe.Price[]
    creating: boolean
    opened: boolean
    refresh: () => void
    onCancel: () => void
}

const ProductManagement: React.FC<ProductManagementInterface> = ({
    product,
    user,
    prices,
    creating,
    opened,
    refresh,
    onCancel,
}) => {
    const [editedProduct, setEditedProduct] = useState({ ...product })
    const [images, setImages] = useState<string[]>(product?.images)
    const [loading, setLoading] = useState(false)
    const [category, setCategory] = useState(product?.metadata?.category || "")
    const [shippingTime, setShippingTime] = useState(product?.metadata?.shippingTime || "")
    const [shippingCountries, setShippingCountries] = useState(
        (product?.metadata?.shipping?.split(",") || []) as any
    )

    const getPrice = (
        prices?.find((price: Stripe.Price) => price.product === product?.id)?.unit_amount! / 100
    ).toString()

    const [price, setPrice] = useState<string>(getPrice)

    useEffect(() => {
        if (creating) {
            setEditedProduct({} as Stripe.Product)
            setImages([])
            setCategory("")
            setShippingTime("")
            setShippingCountries([])
            setPrice("")
            return
        }

        setEditedProduct({ ...product })
        setImages(product?.images || [])
        setCategory(product?.metadata?.category || "")
        setShippingTime(product?.metadata?.shippingTime || "")
        setShippingCountries((product?.metadata?.shipping?.split(",") || []) as any)
        setPrice(getPrice)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [product, creating])

    const processLink = async (
        stripe: Stripe,
        price: number,
        currency: string,
        productId: string,
        callback: () => void
    ) => {
        try {
            const processPrice = async (
                stripe: Stripe,
                price: number,
                currency: string,
                productId: string
            ) => {
                return await stripe.prices.create({
                    unit_amount: price * 100,
                    currency: currency,
                    product: productId,
                })
            }

            await processPrice(stripe, price, currency, productId)
            callback()
        } catch (e) {
            setLoading(false)
            toast.error((e as any).message, toasted())
        }
    }

    const updateProduct = async () => {
        try {
            setLoading(true)
            const stripe = new Stripe(user.apiKey)
            await stripe.products.update(editedProduct?.id, {
                name: editedProduct?.name,
                description: editedProduct?.description,
                images: images?.length > 0 ? images : [editedProduct?.images[0]],
                active: editedProduct?.active,
                metadata: {
                    category,
                    shipping: shippingCountries.toString(),
                    shippingTime: shippingTime,
                },
            })

            processLink(stripe, formatStripeNumber(Number(price)), "aud", editedProduct?.id, () => {
                onCancel()
                refresh()
                setLoading(false)
            })
        } catch (e) {
            setLoading(false)
            toast.error((e as any).message, toasted())
        }
    }

    const createProduct = async () => {
        try {
            setLoading(true)
            const stripe = new Stripe(user.apiKey)
            const createdProduct = await stripe.products.create({
                name: editedProduct?.name,
                description: editedProduct?.description || "",
                images: images?.length > 0 ? images : [],
                active: editedProduct?.active,
                metadata: {
                    category,
                    shipping: shippingCountries.toString(),
                    shippingTime: shippingTime,
                },
            })

            processLink(stripe, formatStripeNumber(Number(price)), "aud", createdProduct.id, () => {
                onCancel()
                refresh()
                setLoading(false)
            })
        } catch (e) {
            setLoading(false)
            toast.error((e as any).message, toasted())
        }
    }

    const handleInputChange = async (e: any) => {
        const { name, value } = e.target
        setEditedProduct({ ...editedProduct, [name]: value })
    }

    const handleCheckboxChange = async (e: any) => {
        const { name, checked } = e.target
        setEditedProduct({ ...editedProduct, [name]: checked })
    }

    const handleDragStart = (e: any, index: number) => {
        e.dataTransfer.setData("index", index.toString())
    }

    const handleDragOver = (e: any) => {
        e.preventDefault()
    }

    const handleDrop = (e: any, newIndex: number) => {
        e.preventDefault()
        const oldIndex = parseInt(e.dataTransfer.getData("index"))
        const newImages = [...images]
        const draggedImage = newImages[oldIndex]

        newImages.splice(oldIndex, 1)
        newImages.splice(newIndex, 0, draggedImage)

        setImages(newImages)
    }

    return (
        <div className={styles.ProductManagement}>
            <div
                onClick={onCancel}
                className={cn(styles.backdrop, opened ? styles.backdropActive : undefined)}></div>
            <div
                style={{ filter: loading ? "brightness(0.8)" : undefined }}
                className={cn(styles.modal, opened ? styles.modalActive : undefined)}>
                <div className={styles.header}>
                    <div className="flex">
                        <img src={images && images?.length > 0 ? images[0] : noImage} alt="icon" />
                        <h1
                            onClick={() => {
                                !creating &&
                                    window.open(
                                        `${window.location.origin}/?s=${
                                            user.managing[0]
                                        }&prod=${editedProduct?.id.replace("prod_", "")}`,
                                        "_blank"
                                    )
                            }}
                            className={styles.titleUrl}>
                            {!creating ? canTruncate(editedProduct?.name, 30) : "New Product"}
                            {!creating && (
                                <span className={"material-symbols-rounded openUrl"}>link</span>
                            )}
                        </h1>
                    </div>
                    <i onClick={onCancel} className="material-symbols-rounded">
                        close
                    </i>
                </div>
                {!creating && (
                    <div className={styles.meta}>
                        <div className={styles.row}>
                            <div>
                                <p className="field">Product ID</p>
                                <b>{editedProduct?.id}</b>
                            </div>
                            <div>
                                <p className="field">Category</p>
                                <b>{category || "N/A"}</b>
                            </div>
                            <div>
                                <p className="field">Creation Date</p>
                                <b>{parseTC(editedProduct?.created)}</b>
                            </div>
                        </div>

                        <div className={styles.row}>
                            <div>
                                <p className="field">Last Updated</p>
                                <b>{parseTC(editedProduct?.updated)}</b>
                            </div>

                            <div>
                                <p className="field">Price</p>
                                <b>{`$${price}`}</b>
                            </div>

                            {!creating && (
                                <div className="flex">
                                    <label htmlFor="active">
                                        <b>Available / In stock</b>
                                    </label>
                                    <input
                                        type="checkbox"
                                        name="active"
                                        checked={creating ? true : editedProduct?.active}
                                        onChange={handleCheckboxChange}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                )}

                <hr />

                <p className={styles.section}>Product Basics</p>

                <div className="flex">
                    <i style={{ fontSize: "2rem" }} className="material-symbols-rounded">
                        label
                    </i>
                    <input
                        type="text"
                        name="name"
                        required
                        maxLength={100}
                        placeholder="Product Name (required)"
                        value={editedProduct?.name || ""}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="flex">
                    <i style={{ fontSize: "2rem" }} className="material-symbols-rounded">
                        description
                    </i>
                    <input
                        type="text"
                        name="description"
                        required
                        maxLength={500}
                        placeholder="Description (required)"
                        value={editedProduct?.description || ""}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="flex">
                    <i style={{ fontSize: "2rem" }} className="material-symbols-rounded">
                        category
                    </i>
                    <input
                        type="text"
                        name="category"
                        maxLength={50}
                        placeholder="Category (optional)"
                        value={category || ""}
                        onChange={(e) => setCategory(e.target.value)}
                    />
                </div>

                <hr />

                <p className={styles.section}>Pricing & Shipping</p>
                <div className="flex">
                    AUD
                    <i style={{ fontSize: "2rem" }} className="material-symbols-rounded">
                        attach_money
                    </i>
                    <input
                        type="text"
                        name="price"
                        required
                        placeholder="Price (required)"
                        maxLength={7}
                        value={price || ""}
                        onChange={(e) => {
                            if (e.target.value.match(/^[0-9]*\.?[0-9]{0,2}$/))
                                setPrice(e.target.value)
                        }}
                    />
                </div>

                {Number(price) < 0.5 && (
                    <p className={styles.warning}>Price must be more than $0.5 AUD</p>
                )}

                <br />

                <details>
                    <summary>View Shipping Choices (optional)</summary>
                    <ShippingOptions
                        shippingTime={shippingTime}
                        setShippingTime={setShippingTime}
                        shippingCountries={shippingCountries}
                        setShippingCountries={setShippingCountries}
                    />
                </details>

                <hr />

                <p className={styles.section}>Cover Icon + Gallery</p>
                <p>Max. 8 images; drag & drop or click to upload. Reorder images by dragging.</p>

                <br />

                <div className={styles.gallery}>
                    {images?.map((image, index) => (
                        <div
                            key={index}
                            className={styles.item}
                            draggable="true"
                            onDragStart={(e) => handleDragStart(e, index)}
                            onDragOver={handleDragOver}
                            onDrop={(e) => handleDrop(e, index)}>
                            <span
                                onClick={() => {
                                    const newImages = [...images]
                                    newImages.splice(index, 1)
                                    setImages(newImages)
                                }}
                                className="flex">
                                {index === 0 ? <b>Product Cover</b> : "Remove"}
                                <i className="material-symbols-rounded">close</i>
                            </span>
                            <img src={image} draggable={false} alt="Product" />
                        </div>
                    ))}

                    {images?.length < 8 && (
                        <Upload
                            style={{ minHeight: "150px", height: "100%" }}
                            skipLoad={true}
                            onImageUploaded={(url) => setImages([...images, url])}
                        />
                    )}
                </div>

                <hr />

                <button
                    onClick={() => {
                        if (!Number(price) || Number(price) < 0.5) {
                            toast.error("Price must be more than $0.5 AUD", toasted())
                            return
                        } else if (price.split(".")[1]?.length > 2) {
                            toast.error("Price should only have 2 decimal places", toasted())
                            return
                        } else if (!editedProduct?.name || !editedProduct?.description) {
                            toast.error("Both product name and description are required", toasted())
                            return
                        } else if (!loading) {
                            if (creating) createProduct()
                            else updateProduct()
                        }
                    }}>
                    {loading ? (
                        <div className="flex">
                            <div className="loader"></div>
                            <p>Saving...</p>
                        </div>
                    ) : creating ? (
                        <div className="flex">
                            <i className="material-symbols-rounded">add</i>
                            Add Product
                        </div>
                    ) : (
                        <div className="flex">
                            <i className="material-symbols-rounded">done_all</i>
                            Save & Finish
                        </div>
                    )}
                </button>
            </div>
        </div>
    )
}

export default ProductManagement
