import React, { useEffect, useState } from "react"
import styles from "./Store.module.scss"
import Stripe from "stripe"
import Product from "./components/Product"
import { API_ROUTE, copyToClipboard, paginate, toasted } from "../../utils"
import { StoreDetailsInterface } from "../../App"
import toast from "react-hot-toast"
import Logo from "../Logo/Logo"
import axios from "axios"
import Cart from "./components/Cart/Cart"
import ViewProduct from "./components/ViewProduct/ViewProduct"

const Store: React.FC = () => {
    const [loaded, setLoaded] = useState(false)
    const [cartOpen, setCartOpen] = useState(false)
    const [myCart, setMyCart] = useState<Stripe.Product[]>([])
    const [storeDetails, setStoreDetails] = useState<StoreDetailsInterface>(
        {} as StoreDetailsInterface
    )
    const [search, setSearch] = useState("")
    const [products, setProducts] = useState<Stripe.Product[]>([])
    const [prices, setPrices] = useState<Stripe.Price[]>([])
    const [categories, setCategories] = useState<{ [category: string]: Stripe.Product[] }>({})
    const [viewing, setViewing] = useState<Stripe.Product>({} as Stripe.Product)

    useEffect(() => {
        ;(async function () {
            try {
                const store = new URLSearchParams(window.location.search).get("s")
                if (store && !loaded) {
                    const { data } = await axios.get(`${API_ROUTE}/get-store/${store}`)
                    setStoreDetails(data)

                    const stripe = new Stripe(data.apiKey)
                    const products = await paginate(stripe, "products")
                    setProducts(products)

                    const url = new URL(window.location.href)
                    const productLink = url.searchParams.get("prod")
                    if (productLink) {
                        const found = products.find(
                            (p: Stripe.Product) => p.id === "prod_" + productLink
                        )
                        if (found) {
                            setViewing(found)
                            url.searchParams.delete("prod")
                            window.history.replaceState({}, "", url.toString())
                        }
                    }

                    setLoaded(true)
                    setPrices(await paginate(stripe, "prices"))
                }
            } catch (err) {
                toast.error(
                    "Sorry, we could not locate the store you're looking for.",
                    toasted("live-store-not-found", 1e10)
                )
            }
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const categories: { [category: string]: Stripe.Product[] } = {}
        products.forEach((product: Stripe.Product) => {
            if (product.metadata.category && product.active) {
                if (categories[product.metadata.category]) {
                    categories[product.metadata.category].push(product)
                } else {
                    categories[product.metadata.category] = [product]
                }
            }
        })
        setCategories(categories)
    }, [products])

    const addToCart = (product: Stripe.Product, quantity: number) => {
        const cart = [...myCart]

        for (let i = 0; i < quantity; i++) cart.push(product)

        setMyCart(cart)
        toast.success(`Added ${product?.name} to cart!`, toasted())
    }

    function storeCreation(dateString: number) {
        const date = new Date(dateString)
        const month = date.toLocaleString("default", { month: "short" })
        const year = date.getFullYear()
        return `${month} ${year}`
    }

    return (
        <div className={styles.Store}>
            <nav>
                <div onClick={() => (window.location.href = "/")} className={styles.logo}>
                    <Logo color="black" width={60} height={"100%"} />
                    <h1>UpStore</h1>
                </div>
                <div className={styles.cart} onClick={() => setCartOpen(!cartOpen)}>
                    <i className="material-symbols-rounded">shopping_cart</i>
                    {myCart.length > 0 && <p>{myCart.length} items</p>}
                </div>
            </nav>

            {!loaded && (
                <div className={styles.loading}>
                    <div className="loader"></div>
                </div>
            )}

            <Cart
                storeDetails={storeDetails}
                cartOpen={cartOpen}
                setCartOpen={setCartOpen}
                setViewing={setViewing}
                myCart={myCart}
                setMyCart={setMyCart}
                prices={prices}
            />
            <ViewProduct
                storeDetails={storeDetails}
                viewing={viewing}
                setViewing={setViewing}
                prices={prices}
                addToCart={addToCart}
            />

            <div
                style={{
                    backgroundImage: `url(${storeDetails?.banner})`,
                    height: !storeDetails?.banner ? 150 : undefined,
                }}
                className={styles.banner}></div>

            <div className={styles.content}>
                {storeDetails?.icon && (
                    <img className={styles.logo} src={storeDetails?.icon} alt="Store Logo" />
                )}
                <div className={styles.headline}>
                    <div>
                        <h1>{storeDetails.name}</h1>
                        <div className={styles.details}>
                            <span className="flex">
                                <i className="material-symbols-rounded">location_on</i>
                                {storeDetails.location}
                            </span>
                            <span className="flex">
                                <i className="material-symbols-rounded">info</i>
                                Created {storeCreation(storeDetails?.created)}
                            </span>
                        </div>

                        <p className={styles.description}>{storeDetails.description}</p>
                    </div>
                    <div className="flex">
                        <div
                            className={styles.share}
                            onClick={() => {
                                copyToClipboard(window.location.href)
                                toast.success("Link copied to clipboard!", toasted())
                            }}>
                            <i className="material-symbols-rounded">share</i>
                            Share
                        </div>
                        <a href={`mailto:${storeDetails.contact}`}>
                            <button>
                                <i className="material-symbols-rounded">mail</i>
                                Contact {storeDetails.name}
                            </button>
                        </a>
                    </div>
                </div>

                <h1>Recently Added</h1>

                <div id="all" className={styles.featuredGrid}>
                    {products
                        .filter((product: Stripe.Product) => product.active)
                        .slice(0, 5)
                        .map((product: Stripe.Product) => (
                            <Product
                                key={product.id}
                                prices={prices}
                                setViewing={setViewing}
                                inline={true}
                                product={product}
                            />
                        ))}
                </div>

                <div className={styles.page}>
                    <aside>
                        <h2>Looking for...</h2>
                        <input
                            type="search"
                            name="search"
                            placeholder={`Search all ${
                                products.filter((product: Stripe.Product) => product.active).length
                            } products`}
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                        />

                        <hr />

                        <div onClick={() => setCartOpen(!cartOpen)} className={styles.cart}>
                            <div className={styles.text}>
                                <i className="material-symbols-rounded">shopping_cart</i>
                                <div>
                                    <b>My Cart</b>
                                    {myCart.length > 0 ? (
                                        <p>{myCart.length} items</p>
                                    ) : (
                                        <p>Empty</p>
                                    )}
                                </div>
                            </div>
                            <i className="material-symbols-rounded">arrow_forward</i>
                        </div>

                        <hr />

                        <h2>Categories</h2>
                        <div className={styles.categories}>
                            <a href="#all">
                                All
                                <span>
                                    {
                                        products.filter((product: Stripe.Product) => product.active)
                                            .length
                                    }
                                </span>
                            </a>
                            {Object.keys(categories)
                                .sort()
                                .map((category: string, index: number) => (
                                    <a key={index} href={`#${category}`}>
                                        {category} <span>{categories[category].length}</span>
                                    </a>
                                ))}
                        </div>
                    </aside>

                    <div>
                        <h1 className={styles.section}>All Products</h1>
                        <div className={styles.productsGrid}>
                            {products
                                .filter(
                                    (product: Stripe.Product) =>
                                        product.active &&
                                        product.name.toLowerCase().includes(search.toLowerCase())
                                )
                                .map((product: Stripe.Product) => (
                                    <Product
                                        key={product.id}
                                        prices={prices}
                                        setViewing={setViewing}
                                        product={product}
                                    />
                                ))}
                        </div>

                        {Object.keys(categories).map((category: string) => (
                            <div key={category}>
                                <h1 className={styles.section} id={category}>
                                    {category}
                                </h1>
                                <div className={styles.productsGrid}>
                                    {categories[category].map((product: Stripe.Product) => (
                                        <Product
                                            key={product.id}
                                            prices={prices}
                                            setViewing={setViewing}
                                            product={product}
                                        />
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Store
