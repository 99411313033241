import React from "react"
import styles from "./Dashboard.module.scss"
import Stripe from "stripe"
import { noImage, toasted } from "../../utils"
import { StoreDetailsInterface, UserInterface } from "../../App"
import Order from "./components/Order"
import { Link } from "react-router-dom"
import Assistant from "../Assistant/Assistant"
import Logo from "../Logo/Logo"
import Transaction from "./components/Transaction"
import toast from "react-hot-toast"

interface DashboardInterface {
    storeDetails: StoreDetailsInterface
    user: UserInterface
    refresh: () => Promise<void>
    products: Stripe.Product[]
    transactions: Stripe.BalanceTransaction[]
    charges: Stripe.Charge[]
    balance: { available: number; pending: number }
}

const Dashboard: React.FC<DashboardInterface> = ({
    storeDetails,
    user,
    refresh,
    products,
    transactions,
    charges,
    balance,
}) => {
    return (
        <div className={styles.Dashboard}>
            <div className="backdrop"></div>
            <div className={styles.header}>
                <div style={{ width: "100%" }} className="flex">
                    <Logo className={styles.logo} width={120} height={"100%"} />
                    <div style={{ width: "100%" }}>
                        <div style={{ justifyContent: "space-between" }} className="flex">
                            <h1>Welcome Back, {user.name}.</h1>
                            <Link
                                className={styles.logout}
                                onClick={() => {
                                    localStorage.removeItem("credentials")
                                    toast.success("We've logged you out successfully.", toasted())
                                }}
                                to="/login">
                                <i className="material-symbols-rounded">logout</i>
                                Logout
                            </Link>
                        </div>
                        <Assistant products={products} refresh={refresh} user={user} />
                    </div>
                </div>
            </div>

            <Link className={styles.store} to="/mystore">
                <div className={styles.current}>
                    <img src={storeDetails.icon || noImage} className={styles.logo} alt="logo" />
                    <h2>{storeDetails.name}</h2>
                    <p>
                        {products.filter((product: Stripe.Product) => product.active).length} active
                        products
                    </p>
                    <button style={{ borderRadius: "10px" }}>
                        <i className="material-symbols-rounded">arrow_forward</i>
                        Manage
                    </button>
                </div>

                <div className="flex">
                    <div className={styles.widget}>
                        <div style={{ gap: "5rem" }} className="flex">
                            <div>
                                <span style={{ fontSize: "1.1rem" }}>Withdrawable Funds</span>
                                <p style={{ fontSize: "3.5rem" }}>${balance.available}</p>
                            </div>
                            <div>
                                <span style={{ fontSize: "1.1rem" }}>Pending Funds</span>
                                <p style={{ fontSize: "3.5rem" }}>${balance.pending}</p>
                            </div>
                        </div>
                    </div>

                    <div className={styles.widget}>
                        {transactions.map(
                            (transaction: Stripe.BalanceTransaction, index: number) => {
                                return <Transaction transaction={transaction} key={index} />
                            }
                        )}
                    </div>
                </div>
            </Link>

            {charges?.length > 0 && (
                <>
                    <br />

                    <h1 className="flex">
                        <i className="material-symbols-rounded">orders</i>
                        Orders
                    </h1>

                    <hr />

                    {charges.map((charge: Stripe.Charge, index: number) => (
                        <Order charge={charge} key={index} />
                    ))}
                </>
            )}
        </div>
    )
}

export default Dashboard
