import React, { useState } from "react"
import styles from "./StoreManagement.module.scss"
import ProductManagement from "../ProductManagement/ProductManagement"
import Stripe from "stripe"
import { StoreDetailsInterface, UserInterface } from "../../App"
import Product from "./components/Product"
import { Link } from "react-router-dom"
import { API_ROUTE, canTruncate, toasted } from "../../utils"
import axios from "axios"
import Upload from "../Upload/Upload"
import toast from "react-hot-toast"
import noBanner from "./no-banner.png"

interface StoreManagementInterface {
    storeDetails: StoreDetailsInterface
    setStoreDetails: React.Dispatch<React.SetStateAction<StoreDetailsInterface>>
    user: UserInterface
    refresh: () => void
    products: Stripe.Product[]
    prices: Stripe.Price[]
}

const StoreManagement: React.FC<StoreManagementInterface> = ({
    storeDetails,
    setStoreDetails,
    user,
    refresh,
    products,
    prices,
}) => {
    const [selectedProduct, setSelectedProduct] = useState<Stripe.Product>(products[0])
    const [layout, setLayout] = useState<"unavailable" | "available" | "all">("available")
    const [creating, setCreating] = useState(false)
    const [opened, setOpened] = useState(false)
    const [search, setSearch] = useState<string>("")

    const cancel = () => {
        setCreating(false)
        setOpened(false)
    }

    const filterProducts = (layout: string) => {
        const searchFilter = (layoutAppliedProducts: Stripe.Product[]) => {
            return layoutAppliedProducts.filter((product: Stripe.Product) =>
                product.name.toLowerCase().includes(search.toLowerCase())
            )
        }

        if (layout === "available")
            return searchFilter(products.filter((product: Stripe.Product) => product.active))
        else if (layout === "unavailable")
            return searchFilter(products.filter((product: Stripe.Product) => !product.active))
        else return searchFilter(products)
    }

    async function updateStore({ icon, banner, description, contact, location }: any) {
        try {
            const response = await axios.post(`${API_ROUTE}/update-store`, {
                store: storeDetails.name,
                icon,
                banner,
                description,
                contact,
                location,
            })

            setStoreDetails(response.data)
        } catch (err) {
            toast(
                "Couldn't update store details, please try again later.",
                toasted("update-store-failed")
            )
        }
    }

    return (
        <div className={styles.StoreManagement}>
            <div className="backdrop"></div>

            <div className={styles.header}>
                <div className={styles.toolbar}>
                    <Link to="/dashboard" className={styles.back}>
                        ← Back
                    </Link>
                    <div className="flex">
                        <input
                            type="text"
                            name="search"
                            placeholder="Search..."
                            value={search}
                            className={styles.search}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                        <button onClick={() => window.open("?s=" + storeDetails.name, "_blank")}>
                            <i className="material-symbols-rounded">outbound</i>
                            See My Live Store
                        </button>
                        <button
                            onClick={() => {
                                setOpened(true)
                                setCreating(true)
                            }}>
                            <i className="material-symbols-rounded">add</i>
                            Add New Product
                        </button>
                    </div>
                </div>

                {!search && (
                    <>
                        <Upload
                            style={{
                                aspectRatio: "18/4",
                                border: "1px solid var(--border)",
                                width: "100%",
                                height: "350px",
                            }}
                            initialImage={storeDetails.banner || noBanner}
                            onImageUploaded={(url) => updateStore({ banner: url })}
                        />

                        <div className={styles.editStore}>
                            <Upload
                                initialImage={storeDetails.icon}
                                onImageUploaded={(url) => updateStore({ icon: url })}
                                placeholder={"Add Logo"}
                            />

                            <div className={styles.details}>
                                <div className="flex">
                                    <h1>{storeDetails.name}</h1>
                                    <div className={styles.statusFlag}>
                                        <i className="material-symbols-rounded">info</i>
                                        Store Management Mode
                                    </div>
                                </div>

                                <div className={styles.grid}>
                                    <div>
                                        <div className="flex">
                                            <i className="material-symbols-rounded">feedback</i>
                                            <b>Store Message</b>
                                        </div>
                                        <p
                                            onClick={() => {
                                                updateStore({
                                                    description: prompt(
                                                        `People can see your message when visiting ${storeDetails.name}`,
                                                        storeDetails.description
                                                    ),
                                                })
                                            }}>
                                            {canTruncate(
                                                storeDetails.description ||
                                                    "Click to edit store message",
                                                100
                                            )}
                                        </p>
                                    </div>

                                    <div className={styles.other}>
                                        <div>
                                            <div className="flex">
                                                <i className="material-symbols-rounded">
                                                    alternate_email
                                                </i>
                                                <b>Contact Email</b>
                                            </div>
                                            <p
                                                onClick={() => {
                                                    updateStore({
                                                        contact: prompt(`Change of contact email`),
                                                    })
                                                }}>
                                                {storeDetails.contact || "Set store contact email"}
                                            </p>
                                        </div>

                                        <div>
                                            <div className="flex">
                                                <i className="material-symbols-rounded">
                                                    location_on
                                                </i>
                                                <b>Location</b>
                                            </div>
                                            <p
                                                onClick={() => {
                                                    updateStore({
                                                        location: prompt(
                                                            `Where is ${storeDetails.name} located?`
                                                        ),
                                                    })
                                                }}>
                                                {storeDetails.location || "Set store location"}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>

            <div className={styles.switch}>
                <div
                    style={{ background: layout === "available" ? "var(--white)" : undefined }}
                    onClick={() => setLayout("available")}>
                    Available Products
                </div>
                <div
                    style={{ background: layout === "unavailable" ? "var(--white)" : undefined }}
                    onClick={() => setLayout("unavailable")}>
                    No Stock/Unavailable
                </div>
                <div
                    style={{ background: layout === "all" ? "var(--white)" : undefined }}
                    onClick={() => setLayout("all")}>
                    All
                </div>
            </div>

            <table className={styles.ProductTable}>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>ID</th>
                        <th>Price</th>
                        <th>Description</th>
                        <th>Created</th>
                        <th>Updated</th>
                    </tr>
                </thead>
                <tbody>
                    {filterProducts(layout).map((product: Stripe.Product) => (
                        <Product
                            key={product.id}
                            prices={prices}
                            setSelectedProduct={setSelectedProduct}
                            product={product}
                            products={products}
                            setOpened={setOpened}
                        />
                    ))}
                </tbody>
            </table>

            <ProductManagement
                product={selectedProduct}
                user={user}
                prices={prices}
                creating={creating}
                opened={opened}
                refresh={refresh}
                onCancel={cancel}
            />
        </div>
    )
}

export default StoreManagement
