import React, { useRef, useState } from "react"
import styles from "../ProductManagement.module.scss"
import { countryCodes, toasted } from "../../../utils"
import toast from "react-hot-toast"

interface ShippingOptionsInterface {
    shippingTime: string
    shippingCountries: string[]
    setShippingCountries: React.Dispatch<React.SetStateAction<string[]>>
    setShippingTime: React.Dispatch<React.SetStateAction<string>>
}

const ShippingOptions: React.FC<ShippingOptionsInterface> = ({
    shippingTime,
    shippingCountries,
    setShippingCountries,
    setShippingTime,
}) => {
    const selectorRef = useRef<HTMLSelectElement>(null)
    const [, setSelected] = useState("")

    const handleSelectChange = () => {
        const selectedValue = selectorRef.current?.value || ""

        if (selectedValue === "") return
        if (shippingCountries.includes(selectedValue)) {
            toast.error("This country is already selected.", toasted())
            return
        }

        setSelected(selectedValue)
        setShippingCountries([...shippingCountries, selectedValue])
    }

    return (
        <div className={styles.shippingOptions}>
            <p>
                To collect postage details from customers, you first need to select countries your
                product ships to. Shipping costs aren't supported yet, please include it in the
                product pricing instead.
            </p>

            <div className={styles.selectedCountries}>
                {shippingCountries.map((country: string) => (
                    <div key={country} className="flex">
                        <b>
                            {
                                countryCodes.find(
                                    (code: [string, string]) => code[0] === country
                                )?.[1]
                            }
                        </b>
                        <p>({country})</p>
                        <i
                            onClick={() => {
                                const newCountries = [...shippingCountries]
                                newCountries.splice(shippingCountries.indexOf(country), 1)
                                setShippingCountries(newCountries)
                            }}
                            className="material-symbols-rounded">
                            close
                        </i>
                    </div>
                ))}
            </div>

            <div className="flex">
                <select
                    ref={selectorRef}
                    value={
                        shippingCountries.length > 0
                            ? "Add another country?"
                            : "Click to select country"
                    }
                    onChange={handleSelectChange}>
                    <option value="">
                        {shippingCountries.length > 0
                            ? "Add another country?"
                            : "Click to select country"}
                    </option>
                    {countryCodes.map((code: [string, string]) => (
                        <option key={code[0]} value={code[0]}>
                            {code[1]}
                        </option>
                    ))}
                </select>
                <i className="material-symbols-rounded">local_shipping</i>
                <select
                    name="shippingTime"
                    value={shippingTime}
                    onChange={(e) => setShippingTime(e.target.value)}>
                    <option value="Select Shipping Time">Select Shipping Time</option>
                    <option value="Same-day delivery">Same Day Delivery</option>
                    <option value="Next-day delivery">Next Day Delivery</option>
                    <option value="Delivers in 1-3 days">1-3 Days Delivery</option>
                    <option value="Delivers in one week">1 Week Delivery</option>
                    <option value="Delivers in 2-3 weeks">2-3 Weeks Delivery</option>
                    <option value="Delivers in one month">1 Month Delivery</option>
                    <option value="Delivers in 2-3 months">2-3 Months Delivery</option>
                </select>
            </div>

            <p className={styles.section}>Billing Scheme & Unit Amount</p>
            <div className="flex">
                <select name="billing_scheme">
                    <option value="per_unit">Paid Per Unit (default)</option>
                </select>
            </div>
        </div>
    )
}

export default ShippingOptions
