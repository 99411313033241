export const API_ROUTE = "https://visioneerlist.herokuapp.com/upstore"
export const noImage =
    "https://morganparmley.com/wp-content/uploads/elementor/thumbs/no-image-p6u1qd6ihsuldnka95qe44afquo1csbvw9psywnkc8.png"

export async function paginate(stripe: any, type: string) {
    const total = []
    let hasMore = true
    let startAfter = ""
    while (hasMore) {
        const obj: any = { limit: 100 }
        if (startAfter) obj["starting_after"] = startAfter
        const products = await stripe[type].list(obj)
        total.push(...products.data)
        hasMore = products.has_more
        startAfter = products.data[products.data.length - 1].id
    }
    return total
}

export function formatStripeNumber(num: number): number {
    const formattedNumber = Number(num.toFixed(2))
    return isNaN(formattedNumber) ? 0.5 : formattedNumber
}

export const toasted = (id?: string | undefined, delay?: number) => {
    const obj: any = {
        style: {
            background: "#222",
            color: "#fff",
            padding: "1rem 2rem",
            borderRadius: "100rem",
        },
    }
    if (id) obj["id"] = id
    if (delay) obj["duration"] = delay
    return obj
}

export const toastMessage = (delay?: number) => {
    const obj: any = {
        style: {
            width: "100%",
            background: "#222",
            color: "#fff",
            padding: "10px 1rem",
            borderRadius: "1rem",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        },
    }
    if (delay) obj["duration"] = delay
    return obj
}

export function copyToClipboard(text: string) {
    const el = document.createElement("textarea")
    el.value = text
    document.body.appendChild(el)
    el.select()
    document.execCommand("copy")
    document.body.removeChild(el)
}

export function parseTC(tc: number, format: string = "default"): string {
    const date = new Date(tc * 1000)
    const formats: { [key: string]: Intl.DateTimeFormatOptions } = {
        default: {
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
        },
    }

    if (!formats[format]) format = "default"
    return date.toLocaleString(undefined, formats[format])
}

export const canTruncate = (text: string, length: number) => {
    if (text?.length > length) return text.slice(0, length) + "..."
    return text
}

export const countryCodes: [string, string][] = [
    ["", ""],
    ["AU", "Australia"],
    ["GB", "United Kingdom"],
    ["US", "United States"],
    ["AC", "Ascension Island"],
    ["AD", "Andorra"],
    ["AE", "United Arab Emirates"],
    ["AF", "Afghanistan"],
    ["AG", "Antigua and Barbuda"],
    ["AI", "Anguilla"],
    ["AL", "Albania"],
    ["AM", "Armenia"],
    ["AO", "Angola"],
    ["AQ", "Antarctica"],
    ["AR", "Argentina"],
    ["AT", "Austria"],
    ["AW", "Aruba"],
    ["AX", "Åland Islands"],
    ["AZ", "Azerbaijan"],
    ["BA", "Bosnia and Herzegovina"],
    ["BB", "Barbados"],
    ["BD", "Bangladesh"],
    ["BE", "Belgium"],
    ["BF", "Burkina Faso"],
    ["BG", "Bulgaria"],
    ["BH", "Bahrain"],
    ["BI", "Burundi"],
    ["BJ", "Benin"],
    ["BL", "Saint Barthélemy"],
    ["BM", "Bermuda"],
    ["BN", "Brunei"],
    ["BO", "Bolivia"],
    ["BQ", "Bonaire, Sint Eustatius and Saba"],
    ["BR", "Brazil"],
    ["BS", "Bahamas"],
    ["BT", "Bhutan"],
    ["BV", "Bouvet Island"],
    ["BW", "Botswana"],
    ["BY", "Belarus"],
    ["BZ", "Belize"],
    ["CA", "Canada"],
    ["CD", "Democratic Republic of the Congo"],
    ["CF", "Central African Republic"],
    ["CG", "Republic of the Congo"],
    ["CH", "Switzerland"],
    ["CI", "Ivory Coast"],
    ["CK", "Cook Islands"],
    ["CL", "Chile"],
    ["CM", "Cameroon"],
    ["CN", "China"],
    ["CO", "Colombia"],
    ["CR", "Costa Rica"],
    ["CV", "Cape Verde"],
    ["CW", "Curaçao"],
    ["CY", "Cyprus"],
    ["CZ", "Czech Republic"],
    ["DE", "Germany"],
    ["DJ", "Djibouti"],
    ["DK", "Denmark"],
    ["DM", "Dominica"],
    ["DO", "Dominican Republic"],
    ["DZ", "Algeria"],
    ["EC", "Ecuador"],
    ["EE", "Estonia"],
    ["EG", "Egypt"],
    ["EH", "Western Sahara"],
    ["ER", "Eritrea"],
    ["ES", "Spain"],
    ["ET", "Ethiopia"],
    ["FI", "Finland"],
    ["FJ", "Fiji"],
    ["FK", "Falkland Islands"],
    ["FO", "Faroe Islands"],
    ["FR", "France"],
    ["GA", "Gabon"],
    ["GD", "Grenada"],
    ["GE", "Georgia"],
    ["GF", "French Guiana"],
    ["GG", "Guernsey"],
    ["GH", "Ghana"],
    ["GI", "Gibraltar"],
    ["GL", "Greenland"],
    ["GM", "Gambia"],
    ["GN", "Guinea"],
    ["GP", "Guadeloupe"],
    ["GQ", "Equatorial Guinea"],
    ["GR", "Greece"],
    ["GS", "South Georgia and the South Sandwich Islands"],
    ["GT", "Guatemala"],
    ["GU", "Guam"],
    ["GW", "Guinea-Bissau"],
    ["GY", "Guyana"],
    ["HK", "Hong Kong"],
    ["HN", "Honduras"],
    ["HR", "Croatia"],
    ["HT", "Haiti"],
    ["HU", "Hungary"],
    ["ID", "Indonesia"],
    ["IE", "Ireland"],
    ["IL", "Israel"],
    ["IM", "Isle of Man"],
    ["IN", "India"],
    ["IO", "British Indian Ocean Territory"],
    ["IQ", "Iraq"],
    ["IS", "Iceland"],
    ["IT", "Italy"],
    ["JE", "Jersey"],
    ["JM", "Jamaica"],
    ["JO", "Jordan"],
    ["JP", "Japan"],
    ["KE", "Kenya"],
    ["KG", "Kyrgyzstan"],
    ["KH", "Cambodia"],
    ["KI", "Kiribati"],
    ["KM", "Comoros"],
    ["KN", "Saint Kitts and Nevis"],
    ["KR", "South Korea"],
    ["KW", "Kuwait"],
    ["KY", "Cayman Islands"],
    ["KZ", "Kazakhstan"],
    ["LA", "Laos"],
    ["LB", "Lebanon"],
    ["LC", "Saint Lucia"],
    ["LI", "Liechtenstein"],
    ["LK", "Sri Lanka"],
    ["LR", "Liberia"],
    ["LS", "Lesotho"],
    ["LT", "Lithuania"],
    ["LU", "Luxembourg"],
    ["LV", "Latvia"],
    ["LY", "Libya"],
    ["MA", "Morocco"],
    ["MC", "Monaco"],
    ["MD", "Moldova"],
    ["ME", "Montenegro"],
    ["MF", "Saint Martin"],
    ["MG", "Madagascar"],
    ["MK", "North Macedonia"],
    ["ML", "Mali"],
    ["MM", "Myanmar"],
    ["MN", "Mongolia"],
    ["MO", "Macao"],
    ["MQ", "Martinique"],
    ["MR", "Mauritania"],
    ["MS", "Montserrat"],
    ["MT", "Malta"],
    ["MU", "Mauritius"],
    ["MV", "Maldives"],
    ["MW", "Malawi"],
    ["MX", "Mexico"],
    ["MY", "Malaysia"],
    ["MZ", "Mozambique"],
    ["NA", "Namibia"],
    ["NC", "New Caledonia"],
    ["NE", "Niger"],
    ["NG", "Nigeria"],
    ["NI", "Nicaragua"],
    ["NL", "Netherlands"],
    ["NO", "Norway"],
    ["NP", "Nepal"],
    ["NR", "Nauru"],
    ["NU", "Niue"],
    ["NZ", "New Zealand"],
    ["OM", "Oman"],
    ["PA", "Panama"],
    ["PE", "Peru"],
    ["PF", "French Polynesia"],
    ["PG", "Papua New Guinea"],
    ["PH", "Philippines"],
    ["PK", "Pakistan"],
    ["PL", "Poland"],
    ["PM", "Saint Pierre and Miquelon"],
    ["PN", "Pitcairn Islands"],
    ["PR", "Puerto Rico"],
    ["PS", "Palestine"],
    ["PT", "Portugal"],
    ["PY", "Paraguay"],
    ["QA", "Qatar"],
    ["RE", "Réunion"],
    ["RO", "Romania"],
    ["RS", "Serbia"],
    ["RU", "Russia"],
    ["RW", "Rwanda"],
    ["SA", "Saudi Arabia"],
    ["SB", "Solomon Islands"],
    ["SC", "Seychelles"],
    ["SE", "Sweden"],
    ["SG", "Singapore"],
    ["SH", "Saint Helena, Ascension and Tristan da Cunha"],
    ["SI", "Slovenia"],
    ["SJ", "Svalbard and Jan Mayen"],
    ["SK", "Slovakia"],
    ["SL", "Sierra Leone"],
    ["SM", "San Marino"],
    ["SN", "Senegal"],
    ["SO", "Somalia"],
    ["SR", "Suriname"],
    ["SS", "South Sudan"],
    ["ST", "São Tomé and Príncipe"],
    ["SV", "El Salvador"],
    ["SX", "Sint Maarten"],
    ["SZ", "Eswatini"],
    ["TA", "Tristan da Cunha"],
    ["TC", "Turks and Caicos Islands"],
    ["TD", "Chad"],
    ["TF", "French Southern and Antarctic Lands"],
    ["TG", "Togo"],
    ["TH", "Thailand"],
    ["TJ", "Tajikistan"],
    ["TK", "Tokelau"],
    ["TL", "East Timor"],
    ["TM", "Turkmenistan"],
    ["TN", "Tunisia"],
    ["TO", "Tonga"],
    ["TR", "Turkey"],
    ["TT", "Trinidad and Tobago"],
    ["TV", "Tuvalu"],
    ["TW", "Taiwan"],
    ["TZ", "Tanzania"],
    ["UA", "Ukraine"],
    ["UG", "Uganda"],
    ["UY", "Uruguay"],
    ["UZ", "Uzbekistan"],
    ["VA", "Vatican City"],
    ["VC", "Saint Vincent and the Grenadines"],
    ["VE", "Venezuela"],
    ["VG", "British Virgin Islands"],
    ["VN", "Vietnam"],
    ["VU", "Vanuatu"],
    ["WF", "Wallis and Futuna"],
    ["WS", "Samoa"],
    ["XK", "Kosovo"],
    ["YE", "Yemen"],
    ["YT", "Mayotte"],
    ["ZA", "South Africa"],
    ["ZM", "Zambia"],
    ["ZW", "Zimbabwe"],
]
